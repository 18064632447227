import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import Hero from '../Stonk-Components/Stonk-Hero';
import Stonk_Carousel from '../Stonk-Components/Stonk-Carousel';
import Stonk_Sign_Up from '../Stonk-Components/Stonk-Sign-Up';

function StonkBetaThankYou(props) {
    return (
        <Container fluid={true}>
            <Hero title={"Thank you for signing up for the Stonk beta"} />

            <Row className="justify-content-center">
                <a className="pl-5 pr-5 text-center " style={{color: '#377df6', fontSize: '20px'}} href="https://testflight.apple.com/join/zUYXo1YN">https://testflight.apple.com/join/zUYXo1YN</a>
            </Row>
        </Container>
    );
}

export default StonkBetaThankYou;