import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBSqi1vH26tlNN6iv0qJ9jMhVHM9K5AjLo",
    authDomain: "nexttrade-68e7c.firebaseapp.com",
    databaseURL: "https://nexttrade-68e7c-default-rtdb.firebaseio.com",
    projectId: "nexttrade-68e7c",
    storageBucket: "nexttrade-68e7c.appspot.com",
    messagingSenderId: "609024077835",
    appId: "1:609024077835:web:9e6816430d0cb22c9ad70e",
    measurementId: "G-W0XJCWEX5T"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();

function uniqueID(){
    function chr4(){
      return Math.random().toString(16).slice(-4);
    }
    return chr4() + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() + chr4() + chr4();
}

function sanitizeEmail(email) {
    // Regular expression pattern to validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the pattern
    if (emailPattern.test(email)) {
        // If the email is valid, return the sanitized email
        return email.trim(); // Remove leading and trailing spaces
    } else {
        // If the email is not valid, return null or handle the error
        return null;
    }
}

export { firebase, database, uniqueID, sanitizeEmail };