import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { render } from 'react-dom'
import { browserHistory, Router, Route, IndexRoute } from 'react-router'

import 'bootstrap/dist/css/bootstrap.css';

import HomePage from './Pages/HomePage';
import ProjectsPage from './Pages/ProjectsPage';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import StonkBetaPage from './Pages/Stonk-Beta-Page';
import StonkBetaThankYouPage from './Pages/Stonk-Thank-You';

ReactDOM.render(
  <Router history={browserHistory}>
    <Route path='/' component={App}>
      <IndexRoute component={HomePage} />
      <Route path="/Projects" component={ProjectsPage} />
      <Route path="/About" component={AboutPage} />
      <Route path="/Contact" component={ContactPage} />
      <Route path="/Stonk-Beta" component={StonkBetaPage} />
    </Route>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
