import React from 'react';
import { Form } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import { firebase, database, uniqueID, sanitizeEmail } from "../Database/firebase";

import vid1 from '../Assets/Videos/ShortAppPromo.mp4';

function JoinStonkBetaView() {
    const history = useHistory();

    const entry_style = {
        color: 'White',
        backgroundColor: '#0f0f0f',
        borderRadius: 1000,
        borderColor: '#0f0f0f',
        height: '55px',
        fontFamily: "Inter-Regular"
    }

    function handleSubmit(e) {
        e.preventDefault();

        var uid = uniqueID();
        const name = e.target.name.value;
        const email = e.target.email.value;
        const sanitizedEmail = sanitizeEmail(email);

        console.log(uid);
        if (sanitizedEmail != "") {
            firebase.database().ref('Beta_Users/' + uid + "/").set({
                name: name,
                email: sanitizedEmail
            }).then(function() {
                history.push("/Stonk-Beta-Thank-You");
            });
        }
    }

    return (
        <div style={{ marginLeft: "36px", marginRight: "36px" }}>
            <div className="flex-container">
                <div className="BetaSignUp">
                    <h1 style={{ color: 'White', marginBottom: '20px', textAlign: 'left', fontFamily: "Inter-SemiBold" }}>
                        Join the Stonk beta:
                    </h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color: 'White', fontFamily: "Inter-Regular" }}>Name</Form.Label>
                            <Form.Control type="Name" id="name" placeholder="J Powell" style={entry_style} />
                        </Form.Group>
                        <Form.Group className="mb-3 formGoruper" controlId="formBasicEmail">
                            <Form.Label style={{ color: 'White', fontFamily: "Inter-Regular" }}>Email address</Form.Label>
                            <Form.Control type="Email" id="email" placeholder="moneyprinter@brrr.com" style={entry_style} />
                            <Form.Text className="text-muted" style={{ fontFamily: "Inter-Regular" }}>
                                * Once you click ' Join Apple Stonk beta' the name and email you provided will be used to send you an invitation to join the Apple beta program. Apple will direct you with futher instructions for getting the beta setup.
                            </Form.Text>
                        </Form.Group>
                        <button className='downloadButton' type="submit" style={{ marginTop: "15px" }}>
                             Join Apple Stonk beta
                        </button>
                    </Form>
                </div>
                <div style={{ flex: 2, marginLeft: "40px"}}>
                    <video autoPlay loop muted playsInline id="video" width="80%" height="auto">
                        <source src={vid1} type='video/mp4' />
                    </video>
                </div>
            </div>
        </div>
    );
}

export default JoinStonkBetaView;