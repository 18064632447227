import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button} from 'react-bootstrap';
import emailjs from 'emailjs-com';

// Components
import Hero from '../Components/Hero';
import Content from '../Components/Content';

class ContactPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: false,
            emailSent: null

        }
    }

    handle_submit(e) {
        e.preventDefault();
        console.log("Sup");
        var templateParams = {
            name: document.getElementById("name").value,
            email: document.getElementById("email").value,
            message: document.getElementById("message").value
        };

        emailjs.sendForm('service_2e0o1bm', 'template_7xu6rx5', e.target, 'user_h5XPUAEjrKg4DjZwdQeGy')
        .then((result) => {
            window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
        }, (error) => {
            console.log("ERROR");
            console.log(error.text);
        });

    }

    render() {
        return (
            <div>
                <Hero title={this.props.title} />
                <Container fluid={true}>
                    <Row className="justify-content-center py-5">
                        <Col md={9} sm={12}>
                            <Form onSubmit={this.handle_submit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" type="Name" id="name" placeholder="Your name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control name="email" type="Email" id="email" placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control name="message" as="textarea" id="message" rows={3} />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>

                    </Row>
                </Container>

            </div>

        );
    }
}

export default ContactPage;