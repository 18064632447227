import React from 'react';

// Components
import Hero from '../Components/Hero';
import Carousel from '../Components/Carousel';
import AboutMeSection from '../Components/AboutMeSection';
import PreviousWork from '../Components/PreviousWork';
import GoToSection from '../Components/GoToSection';
import Education from '../Components/Education';

function HomePage(props) {
    return (
        <div>
          
        </div>
      );
}

export default HomePage;