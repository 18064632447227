import React from 'react';
import { Form } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { firebase, database, uniqueID, sanitizeEmail } from "../Database/firebase";
import "../App.css"

function DownloadOniOSAppStoreView() {

    const entry_style = {
        color: 'White',
        backgroundColor: '#0f0f0f',
        borderRadius: 1000,
        borderColor: '#0f0f0f',
        height: '55px',
        fontFamily: "Inter-Regular"
    }

    function goToAppStore(e) {
        e.preventDefault();

        window.open('https://apps.apple.com/us/app/stonk/id1558279818', '_blank');
    }

    function handleSubmit(e) {
        e.preventDefault();

        goToAppStore(e);

        var uid = uniqueID();
        const email = e.target.email.value;
        const sanitizedEmail = sanitizeEmail(email);

        if (sanitizedEmail != "") {
            firebase.database().ref('ReferredEmailsFromWeb/OutgoingMail/' + uid + "/").set({
                Email: sanitizedEmail
            }).then(function() {
                document.getElementsByClassName("downloadButton")
            });
        }
    }

    return (
        <Container style={{marginTop: '60px'}}>
            <Row>
                <Col>
                    <h1 style={{color: 'White', marginBottom: '20px', textAlign: 'center', fontFamily: "Inter-SemiBold"}}>
                        Join the Stonk
                        Community!
                    </h1>

                    <p style={{color: 'GrayText', textAlign: 'center', fontSize: '14px', fontFamily: "Inter-Regular"}}>With over 500 users Stonk allows them to build watchlists with their friends, track future investments together, join communities dedicated to the stocks you love, and so much more! Join today by downloading Stonk on the Apple AppStore!</p>
                    
                    <Form onSubmit={handleSubmit} style={{marginTop: "40px"}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{color: 'White', fontFamily: "Inter-Regular"}}>Refer a friend to join with you! <span style={{ color: 'GrayText', fontFamily: "Inter-Regular", fontSize: "12px"}}>(optional)</span></Form.Label>

                            <Form.Control type="Email" id="email" placeholder="invite-your-friends@gmail.com" style={entry_style} />

                            <Form.Text className="text-muted" style={{fontFamily: "Inter-Regular"}}>
                                * If the field above has a valid email address and you press ' Download on Apple' a one-time email will be sent providing information to download Stonk.
                            </Form.Text>
                        </Form.Group>
                        <button className='downloadButton' type="submit" style={{marginTop: "15px"}}>
                             Download on Apple
                        </button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default DownloadOniOSAppStoreView;