import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import Hero from '../Components/Hero';


function AboutPage(props) {
    return (
        <div>
            <Hero title={'About Stonk'} location={'a'} />

            <Container fluid={true} className="About-Me-Page-Container">
                <Row className="justify-content-center">
                    <p className="pl-5 pr-5 text-center " style={{color: 'White'}}>Your group chats are for memes, not stocks.</p>
                    <p className="pl-5 pr-5 text-center " style={{color: 'White'}}>Do some of your friends ever get tired of hearing about stocks when they are just trying to look at memes? Well, we took that idea and mashed it into one big informational app for stocks.</p>
                    <p className="pl-5 pr-5 text-center " style={{color: 'White'}}>With Stonk you are able to create watchlists with your friends that actually want to hear about all your stock plays, but we didn't stop there. We added a full stock page to view chart data, stock comments, options, stock analysis/price target, and more! You can look at all the stock data before adding it to your watchlist instead of jumping back and forth between apps.</p>
                    <p className="pl-5 pr-5 text-center " style={{color: 'White'}}>Stonk communities are a way for you to stay in the loop with stock DD, memes, and more! Join or create communities for your favorite stocks to post useful information and memes.</p>
                    <p className="pl-5 pr-5 text-center " style={{color: 'White'}}>We also have packed in a complete market news area. In our market news area, you are able to view fresh news articles about the stock market, filter news by specific stocks, and article discussions!</p>
                    <p className="pl-5 pr-5 text-center " style={{color: 'White'}}>With article discussions, you and other Stonkers are able to chat back and forth about the news article. You can use this feature to interpret the news article for someone who may not know exactly what the article is about!</p>

                </Row>
            </Container>

        </div>
    );
}

export default AboutPage;