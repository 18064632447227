import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { browserHistory } from 'react-router';
import Container from "react-bootstrap/Container";
import NavBar from "react-bootstrap/NavBar";
import Nav from "react-bootstrap/Nav";
import './App.css';

// Components
import Footer from './Components/Footer';
import HomePage from './Pages/HomePage';
import ProjectsPage from './Pages/ProjectsPage';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import StonkBetaPage from './Pages/Stonk-Beta-Page';
import StonkBetaThankYouPage from './Pages/Stonk-Thank-You';

import './Fonts/Inter-SemiBold.ttf';
import './Fonts/Inter-Regular.ttf';

import logo from './Assets/Images/Stonk-Logo.png';

const linkStyle = {
  color: '#2783d9'
};

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      color: "#3b3b3b",
      title: 'Stonk App',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'About us', path: '/AboutUs' },
        { title: 'Download', path: '/Download' },
        { title: 'Donate', path: '/Donate' },
        { title: 'Thank you for singing up for the Stonk Beta', path: '/Stonk-Beta-Thank-You' }
      ],
      home: {
        title: 'Stonk',
        subTitle: 'Featured Projects'
      },
      about: {
        title: 'About Stonk'
      },
      contact: {
        title: 'Let\'s Work Together'
      }
    }
  }

  render() {
    return (
      <Router history={browserHistory}>
        <Container className="p-0" fluid={true} style={{ backgroundColor: 'Black' }}>

          <NavBar className="border-bottom" bg="transparent" expand="md">
            <NavBar.Brand style={{ display: 'flex', alignItems: 'center', color: 'White', fontFamily: 'Inter-SemiBold', fontSize: "28px" }}>
              <img src={logo} style={{ marginRight: "10px" }} />
              Stonk
            </NavBar.Brand>

            <NavBar.Toggle className="border-0" aria-controls="navBar-toggle" />
            <NavBar.Collapse id="navBar-toggle">
              <Nav className="ml-auto" >
                <Link className="nav-link" to="/" style={{ color: 'White', fontFamily: 'Inter-Regular' }}>Home</Link>
                <Link className="nav-link" to="/AboutUs" style={{ color: 'White', fontFamily: 'Inter-Regular' }}>About Stonk</Link>
                <Link className="nav-link" to="/Download" style={{ color: 'White', fontFamily: 'Inter-Regular' }}>Download</Link>
              </Nav>
            </NavBar.Collapse>
          </NavBar>

          <Route path="/" exact render={() => <StonkBetaPage title={this.state.home.title} subTitle={this.state.home.subTitle} />} />
          <Route path="/AboutUs" render={() => <AboutPage title={this.state.about.title} />} />
          <Route path="/Download" render={() => <StonkBetaPage title={this.state.contact.title} />} />
          <Route path="/Stonk-Beta-Thank-You" render={() => <StonkBetaThankYouPage title={this.state.contact.title} />} />
          <Footer />

        </Container>
      </Router>
    );
  }
}

export default App;
