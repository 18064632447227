import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import Hero from '../Stonk-Components/Stonk-Hero';
import ProjectCard from '../Components/ProjectCard';

function ProjectsPage(props) {
    return (

        <div>

            <Container fluid={true} className="project-page-container">
                <Hero title={"Under Construction!"} />
{/* 
                <h3 className="display-5 font-weight-light text-center border-bottom">iOS Projects - Swift/Obj-C</h3>
                <Row className="justify-content-center mt-4">
                    <Col md={11} sm={12} className="">
                        <ProjectCard />
                    </Col>
                </Row>

                <h3 className="display-5 font-weight-light text-center border-bottom mt-5">Websites</h3>
                <Row className="justify-content-center mt-4">
                    <Col md={11} sm={12} className="">
                        <ProjectCard />
                    </Col>
                </Row>

                <h3 className="display-5 font-weight-light text-center border-bottom mt-5">Java - Coding Problems</h3>
                <Row className="justify-content-center mt-4">
                    <Col md={11} sm={12} className="">
                        <ProjectCard />
                    </Col>
                </Row>

                <h3 className="display-5 font-weight-light text-center border-bottom mt-5">Python</h3>
                <Row className="justify-content-center mt-4">
                    <Col md={11} sm={12} className="">
                        <ProjectCard />
                    </Col>
                </Row>

                <h3 className="display-5 font-weight-light text-center border-bottom mt-5">C/C++ Scripts</h3>
                <Row className="justify-content-center mt-4">
                    <Col md={11} sm={12} className="">
                        <ProjectCard />
                    </Col>
                </Row> */}
            </Container>
        </div>

    );
}

export default ProjectsPage;