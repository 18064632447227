import React, { useEffect } from 'react';
import { useRef, useState } from "react";

// Components
import CardInfo from '../Components/CardInfo';

function Card(props) {

    if (props.item.vidSrc != "") {
        return (
            <div className="d-inline-block sv-card" onClick={(e) => props.click(props.item)}>
                <video autoPlay loop muted playsInline id="video" className="sv-card-img">
                    <source src={props.item.vidSrc} type='video/mp4' />
                </video>
                { props.item.selected && <CardInfo title={props.item.title} subTitle={props.item.subTitle} link={props.item.link} />}
            </div>
        );
    } else {
        return (
            <div className="d-inline-block sv-card" onClick={(e) => props.click(props.item)}>
                <img className="sv-card-img" src={props.item.imgSrc} alt={props.item.imgSrc} />
                { props.item.selected && <CardInfo title={props.item.title} subTitle={props.item.subTitle} link={props.item.link} />}
            </div>
        );
    }

}

export default Card;