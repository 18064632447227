import React from 'react';
import { useSpring, animated} from 'react-spring';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function CardInfo(props) {

    const style = useSpring({opacity: 1, from: {opacity: 0}});

    if (props.link == '/Stonk-Beta') {
        return (
            <animated.div className="sv-card-info" style={style}>
    
                <p className="sv-card-title">{props.title}</p>
                <p className="sv-card-subTitle">{props.subTitle}</p>
    
                <Link to="/Stonk-Beta">View</Link>
    
            </animated.div>
        );
    } else if (props.link == '') {
        return (
            <p></p>
        );
    } else {
        return (
            <animated.div className="sv-card-info" style={style}>
    
                <p className="sv-card-title">{props.title}</p>
                <p className="sv-card-subTitle">{props.subTitle}</p>
    
                <a href={props.link} target="_blank" rel="noopener noreferer">View</a>
    
            </animated.div>
        );
    }
}

export default CardInfo;