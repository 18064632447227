import React from 'react';
import ReCAPTCHA from "react-recaptcha-v3";

// Components
import Hero from '../Stonk-Components/Stonk-Hero';
import Stonk_Carousel from '../Stonk-Components/Stonk-Carousel';
import Stonk_Sign_Up from '../Stonk-Components/Stonk-Sign-Up';
import DownloadOniOSAppStoreView from '../Stonk-Components/DownloadOniOSAppStoreView';
import JoinStonkBetaView from '../Stonk-Components/JoinStonkBetaView';

import vid1 from '../Assets/Videos/Stonk.mp4';

function StonkBetaPage(props) {
    return (
        <div>
            <div className="flex-container">
                <div className='BigVideo'>
                    <video autoPlay loop muted playsInline id="video" controls>
                        <source src={vid1} type='video/mp4' />
                    </video>
                </div>

                <div className='DownloadOniOSAppStoreView'>
                    <DownloadOniOSAppStoreView />
                </div>
            </div>

            <div style={{marginTop: "40px"}}>
                <JoinStonkBetaView />
            </div>
        </div>
    );
}

export default StonkBetaPage;